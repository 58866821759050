import React from 'react';
import Header from './components/Header';
import FeatureSection from './components/FeatureSection';
import HowItWorks from './components/HowItWorks';
import AboutUs from './components/AboutUs';
import JoinUs from './components/JoinUs';
import Footer from './components/Footer';
import './index.css';

const App = () => (
    <div className="App">
        <Header/>
         <FeatureSection/>
        <HowItWorks/>
        <AboutUs/>
        <JoinUs/>
        <Footer/>

    </div>
);

export default App;
