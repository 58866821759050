import React from 'react';
import hands from '../assets/images/hands2.svg'

const Modal = ({show, handleClose}) => {
    const handleClickOutside = (e) => {
        if (e.target.id === 'modal-overlay') {
            handleClose();
        }
    };


    return (
        show ?
            <div className='relative'>
                <div id='modal-overlay'
                     className=" fixed inset-0 flex  items-center justify-center bg-[#511E34] bg-opacity-50 z-1000 "
                     onClick={handleClickOutside}>
                    <div className="bg-white p-8 rounded-lg text-center max-w-lg  mx-10">
                        {<img src={hands} alt="Rocket Icon" className="w-12 mx-auto mb-4"/>}
                        <h2 className="text-xl text-[#7E7E7E] font-light mb-2"><span className='text font-bold'>Thank you for Joining</span> our
                            Pre-Launch Invite.</h2>
                        <p className="mb-4 text-[#7E7E7E]">Our team will follow up soon!</p>
                        <button onClick={handleClose}
                                className="bg-orange-500 hover:bg-orange-600 text-white px-14 py-2 rounded-full">Thanks
                        </button>
                    </div>
                </div>
            </div>
            : null
    );
};

export default Modal;
