import React from 'react';
import logo from '../assets/images/logo.svg'

const AboutUs = () => (
    <section id='about' className=" mx-6 sm:mx-16 my-4 sm:my-20 rounded-xl bg-white py-4 sm:py-10 px-4 sm:px-10 items-center flex flex-col ">

        <h1 className='text-2xl sm:text-4xl font-extrabold'><span className='text-primary'>Who</span> we are</h1>

        <img src={logo} className='h-28 w-28 sm:h-48 sm:w-48 mt-4 sm:mt-10'/>

        <div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-40 justify-between mt-4 sm:mt-12 mx-4 sm:mx-24'>

            <div className='sm:w-1/2 w-full'>
                <h4 className='font-bold text-base sm:text-xl text-primary uppercase'>We Help</h4>
                <p className='text-base sm:text-lg mt-2'>Home-based chefs turn their passion for food into profit by connecting them with foodies who seek diverse, local, and authentic homemade dishes. </p>
            </div>

            <div className='sm:w-1/2 w-full'>
                <h4 className='font-bold text-base sm:text-xl text-primary uppercase'>kitible</h4>

                <p className='text-base sm:text-lg mt-2'>Every homemade dish tells a unique story, making us the vibrant hub of a global community united by the love of homemade food. </p>
            </div>
        </div>
    </section>
);

export default AboutUs;
