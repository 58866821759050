import React, {useEffect, useState} from 'react';
import logo from '../assets/images/logo.svg';
import heroImage from '../assets/images/hero-image.png';

const Header = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <header id='#'>
            <div className='flex flex-col'>
                <div
                    className={`w-full pl-2 sm:pl-16 py-2 flex flex-row items-center  sm:absolute   ${scrolled ? 'bg-white shadow-lg' : ''}`}>
                    <a href='/'>
                        <img src={logo} className='h-8 w-8 sm:h-20 sm:w-20'/>
                    </a>
                    <nav className="ml-1 sm:ml-9">
                        <ul className="flex space-x-2 sm:space-x-9 text-sm sm:text-lg">
                            <li><a href="#" className="text-gray-800 font-bold hover:text-orange-500">Home</a></li>
                            <li><a href="#about" className="text-gray-800 font-bold hover:text-orange-500">About</a>
                            </li>
                            <li><a href="#features"
                                   className="text-gray-800 font-bold hover:text-orange-500">Features</a></li>
                            <li><a href="#early" className="text-gray-800 font-bold hover:text-orange-500">Early
                                Access</a></li>
                        </ul>
                    </nav>
                </div>

                <div className='flex flex-col-reverse sm:flex-row  justify-between'>

                    <div className='sm:w-5/10 py-5  sm:ml-16 mt-4 mr-4 sm:mt-10 sm:pr-40
                          w-full flex sm:items-start items-center flex-col pt-10 sm:pt-24 text-center lg:text-start'>
                        <h1 className='text-2xl sm:text-6xl font-extrabold leading-tight uppercase'>
                            <span className='text-primary'>Connecting</span> Kitchen to Table
                            <br/>
                            One <span className='text-primary'>Homemade</span> Dish
                            at a time
                        </h1>

                        <div className='mt-4 lg:mt-24 text-base sm:text-xl	'>
                            <p>
                                Discover and share authentic homemade cuisine,<br/>
                                Food is an expression of Love!
                                <br/><br/>
                                Join our food lover community around the world
                            </p>
                        </div>


                        <a href='#early'>
                            <div className='cursor-pointer hover:shadow-md inline-block mt-3 lg:mt-24 text-base sm:text-lg
                         rounded-full bg-gradient-to-r from-primary to-primary-400 py-2.5 px-10 font-bold text-white '>
                                Pre-Launch Invite
                            </div>
                        </a>
                    </div>

                    <div className='w-full sm:w-3/10 flex items-end justify-end  sm:relative  self-start'>
                        <img src={heroImage}/>
                    </div>

                </div>
            </div>
        </header>
    );
};


export default Header;
