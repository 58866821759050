import React from 'react';
import frameapp1 from '../assets/images/app-frame1.png'
import frameapp2 from '../assets/images/app-frame2.png'
import frameapp3 from '../assets/images/app-frame3.png'

const HowItWorks = () => (
    <section className="mx-2 sm:mx-16 mt-10  sm:py-16 px-6 sm:px-16 flex  flex-col justify-between  ">
        <h1 className='font-extrabold text-2xl sm:text-4xl leading-tight'>How It Works</h1>

        <div className='flex flex-col lg:flex-row self-center w-full '>
            <img src={frameapp1} className='lg:w-1/3  pr-20 lg:pr-10 self-start lg:mr-0 object-contain  '/>
            <img src={frameapp2} className='lg:w-1/3  pl-20 lg:pl-0 self-start lg:pr-10 lg:mr-0 lg:mt-28 object-contain '/>
            <img src={frameapp3} className='lg:w-1/3  pr-20 lg:pr-0 self-start lg:pl-10 sm:mr-0 lg:mt-60 object-contain '/>
        </div>

    </section>
);

export default HowItWorks;
