import React from 'react';

const Footer = () => (
    <footer className="  text-white py-2 sm:py-4 bg-dark mt-8 sm:mt-16">
        <div className="container mx-auto text-base sm:text-lg text-center ">
            <p>© {new Date().getFullYear()} Kitible. All Rights Reserved.</p>
        </div>
    </footer>
);

export default Footer;
